
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';

import { useRouter } from 'vue-router';

import AuthService from '@/services/auth.service';

import SVG from '@/components/SVG.vue';
import Loader from '@/components/Loader.vue';
import Input from '@/components/form/Input.vue';

export default defineComponent({
	components: {
		SVG,
		Loader,
		Input,
	},

	setup() {
		const router = useRouter();

		onMounted(async () => {
			await AuthService.me().then((response) => {
				if (response.can('')) {
					router.push({ name: 'Projects' });
				}
			});
		});

		const form = reactive({
			email: '',
			password: '',
			error: '',
		});

		const formIsValid = computed(() => {
			return !!form.email && !!form.password;
		});

		const loading = ref(false);

		async function login() {
			if (loading.value || !formIsValid.value) {
				return;
			}

			form.error = '';
			loading.value = true;

			await AuthService.login(form.email, form.password)
				.then(async (response) => {
					if (!response) {
						form.error = 'Skeivur teldupostbústaður ella loyniorð';
						return;
					}
					await AuthService.me();

					router.push({ name: 'Projects' });
				})
				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			form,
			formIsValid,
			loading,

			// functions
			login,
		};
	},
});
